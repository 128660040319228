<template>
  <div style="height:100%;">
    <el-alert :title="connectStatus" v-if="connectStatus" center show-icon type="error" :closable="false">
    </el-alert>
    <div v-if="active==0" style="display:flex;flex-direction:column;">
      <el-card style="background-color:#E6A23C;" :body-style="{display:'flex',justifyContent:'space-around'}">
        <div style="flex:1;display:flex;flex-direction:column;color:white;font-weight:900;text-align:center;border-right:solid 1px #DCDFE6">
          <i class="el-icon-s-opportunity" :style="'font-size:30px;'+'color:'+(model32.ChuiZhuangTai==1?'#67C23A':'white')"></i>
          <span>上升</span>
        </div>
        <div style="flex:1;display:flex;flex-direction:column;color:white;font-weight:900;text-align:center;border-right:solid 1px #DCDFE6">
          <i class="el-icon-s-opportunity" :style="'font-size:30px;'+'color:'+(model32.ChuiZhuangTai==2?'#67C23A':'white')"></i>
          <span>下降</span>
        </div>
        <div style="flex:1;display:flex;flex-direction:column;color:white;font-weight:900;text-align:center;border-right:solid 1px #DCDFE6">
          <i class="el-icon-s-opportunity" :style="'font-size:30px;'+'color:'+(model32.GongZuoZhuangTai==1?'#67C23A':'white')"></i>
          <span>运行</span>
        </div>
        <div style="flex:1;display:flex;flex-direction:column;color:white;font-weight:900;text-align:center;">
          <i class="el-icon-s-opportunity" :style="'font-size:30px;'+'color:'+(model32.JiShu==1?'#67C23A':'white')"></i>
          <span>计数</span>
        </div>
      </el-card>
      <el-card style="background-color:#409EFF;">
        <div style="color:white;font-weight:900;text-align:center;font-size:30px;">运行值</div>
        <el-divider></el-divider>
        <div style="color:green;font-weight:900;text-align:center;font-size:40px;">{{model32.YunXingZhi}}</div>
      </el-card>
      <el-card style="background-color:#909399;">
        <div style="color:white;font-weight:900;text-align:center;font-size:30px;">设置值</div>
        <el-divider></el-divider>
        <div style="color:green;font-weight:900;text-align:center;font-size:40px;">{{model32.SheZhiZhi}}</div>
      </el-card>
    </div>
    <WapJSY002Query :serialNumber="123" v-if="active==1"></WapJSY002Query>
    <div v-if="active==2">
      <van-cell-group title="标签打印机序列号">
        <van-cell center title="试验结束后打印标签">
          <template #right-icon>
            <van-switch v-model="settingModel.ifPrintLabel" size="15"></van-switch>
          </template>
        </van-cell>
        <van-field v-model="settingModel.labelPrinterSN" v-show="settingModel.ifPrintLabel" label="打印机序列号" clearable placeholder="请输入标签打印机序列号" right-icon="scan"></van-field>
      </van-cell-group>
      <van-button block type="info" @click="saveSetting">保存</van-button>
    </div>
    <van-tabbar v-model="active">
      <van-tabbar-item icon="chat">操作</van-tabbar-item>
      <van-tabbar-item icon="graphic">查询</van-tabbar-item>
      <van-tabbar-item icon="setting">设置</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
import WapJSY002Query from "./WapJSY002Query.vue";
Vue.use(Vant);
export default {
  components: { WapJSY002Query },
  props: ["serialNumber"],
  data() {
    return {
      active: 1,
      model32: { YunXingZhi: 0, SheZhiZhi: 0 },
      connectStatus: "",
      settingModel: { labelPrinterSN: "" },
    };
  },
  methods: {
    initWebSocket() {
      const wsuri = process.env.VUE_APP_WebSocketURL;
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    websocketonopen() {
      //连接建立之后执行send方法发送数据
      this.websocketsend(
        JSON.stringify({
          deviceType: "JSY002",
          serialNumber: this.serialNumber.toUpperCase(),
          command: "32",
        })
      );
    },
    websocketonerror() {
      //连接建立失败重连
      this.initWebSocket();
    },
    websocketonmessage(e) {
      //数据接收
      if (e.data == "0") {
        this.connectStatus = "未连接";
      } else {
        this.connectStatus = "";
        this.model32 = JSON.parse(e.data);
      }
      setTimeout(() => {
        this.websocketsend(
          JSON.stringify({
            deviceType: "JSY002",
            serialNumber: this.serialNumber.toUpperCase(),
            command: "32",
          })
        );
      }, 200);
    },
    websocketsend(Data) {
      //数据发送
      this.websock.send(Data);
    },
    websocketclose(e) {
      //关闭
      console.log("断开连接", e);
      this.connectStatus = "服务未启动";
    },
    initSetting() {
      let that = this;
      that.axios
        .post("Base_DeviceConfig_JSY/GetModelBySerialNumber", {
          onlyText: that.serialNumber,
        })
        .then(function (response) {
          that.settingModel = response.data.data;
        });
    },
    saveSetting() {
      let that = this;

      //   if (that.settingModel.labelPrinterSN.length != 10) {
      //     return;
      //   }
      that.axios
        .post("Base_DeviceConfig_JSY/SaveModel", that.settingModel)
        .then(function (response) {
          if (response.data.code == 101) {
            that.$notify({ type: "success", message: "保存成功" });
          }
        });
    },
  },
  mounted() {
    let that = this;
    that.initWebSocket();
    that.initSetting();
    //获取公众号扫码的凭证
    that.axios
      .post("OfficialAccount/GetWXScanAPI", { onlyText: window.location.href })
      .then(function (response) {
        that.wx = require("weixin-js-sdk");
        that.wx.config({
          debug: false,
          appId: response.data.data.appId,
          timestamp: response.data.data.timestamp,
          nonceStr: response.data.data.nonceStr,
          signature: response.data.data.signature,
          jsApiList: ["scanQRCode"],
        });
        that.wx.ready(function () {});
        that.wx.error(function (err) {
          alert("error" + JSON.stringify(err));
        });
      });
  },
};
</script>

<style>
</style>